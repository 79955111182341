/**
 * 定期利用券購入履歴のクエリパラメータコントロール用middleware
 */
import { usePassState } from '@/composables/store/usePassStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { storedPassPayments } = usePassState();

  if (!to.query.page) {
    return navigateTo({
      path: '/mypage/pass',
      query: {
        page: storedPassPayments.value.page || 1,
      },
    });
  }
});
